var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, memo, useEffect } from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useNavigate, useLocation } from 'react-router-dom';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Slide, Popper, InputBase, Autocomplete, InputAdornment, ClickAwayListener, } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
import flattenArray from '../../../utils/flattenArray';
// components
import Iconify from '../../../components/iconify';
import { IconButtonAnimate } from '../../../components/animate';
import SearchNotFound from '../../../components/search-not-found';
//
import { useAuthContext } from '../../../auth/useAuthContext';
import navConfigItems from '../nav/navConfig';
// ----------------------------------------------------------------------
var APPBAR_MOBILE = 64;
var APPBAR_DESKTOP = 92;
var StyledSearchbar = styled('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (__assign(__assign({}, bgBlur({ color: theme.palette.background.default })), (_b = { top: 0, left: 0, zIndex: 99, width: '100%', display: 'flex', position: 'absolute', alignItems: 'center', height: APPBAR_MOBILE, padding: theme.spacing(0, 3), boxShadow: theme.customShadows.z8 }, _b[theme.breakpoints.up('md')] = {
        height: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5),
    }, _b)));
});
var StyledPopper = styled(function (props) { return _jsx(Popper, __assign({}, props)); })(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            left: "8px !important",
            top: "".concat(APPBAR_MOBILE + 8, "px !important"),
            width: 'calc(100% - 16px) !important',
            transform: 'none !important'
        },
        _b[theme.breakpoints.up('md')] = {
            top: "".concat(APPBAR_DESKTOP + 8, "px !important"),
        },
        _b['& .MuiAutocomplete-paper'] = {
            padding: theme.spacing(1, 0),
        },
        _b['& .MuiListSubheader-root'] = {
            '&.MuiAutocomplete-groupLabel': __assign(__assign(__assign({}, bgBlur({ color: theme.palette.background.neutral })), theme.typography.overline), { top: 0, margin: 0, lineHeight: '48px', borderRadius: theme.shape.borderRadius }),
        },
        _b['& .MuiAutocomplete-listbox'] = {
            '& .MuiAutocomplete-option': {
                padding: theme.spacing(0.5, 2),
                margin: 0,
                display: 'block',
                border: "dashed 1px transparent",
                borderBottomColor: theme.palette.divider,
                '&:last-of-type': {
                    borderBottomColor: 'transparent',
                },
                '&:hover': {
                    borderColor: theme.palette.primary.main,
                    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
                },
            },
        },
        _b);
});
function Searchbar() {
    var navigate = useNavigate();
    var user = useAuthContext().user;
    var navConfig = navConfigItems();
    var pathname = useLocation().pathname;
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var _b = useState(''), searchQuery = _b[0], setSearchQuery = _b[1];
    var reduceItems = navConfig
        .map(function (list) { return handleLoop(list.items, list.subheader); })
        .flat();
    var allItems = flattenArray(reduceItems).map(function (option) {
        var group = splitPath(reduceItems, option.path);
        return {
            group: group && group.length > 1 ? group[0] : option.subheader,
            title: option.title,
            path: option.path,
            indexKey: 'minimal',
        };
    });
    useEffect(function () {
        if (open) {
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
    var handleOpen = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var handleClick = function (path) {
        if (path.includes('http')) {
            window.open(path);
        }
        else {
            navigate(path);
        }
        handleClose();
    };
    var handleKeyUp = function (event) {
        if (event.key === 'Enter') {
            handleClick(searchQuery);
        }
    };
    return (_jsx(ClickAwayListener, __assign({ onClickAway: handleClose }, { children: _jsxs("div", { children: [!open && (_jsx(IconButtonAnimate, __assign({ onClick: handleOpen }, { children: _jsx(Iconify, { icon: "eva:search-fill" }) }))), _jsx(Slide, __assign({ direction: "down", in: open, mountOnEnter: true, unmountOnExit: true }, { children: _jsx(StyledSearchbar, { children: _jsx(Autocomplete, { sx: { width: 1, height: 1 }, autoHighlight: true, disablePortal: true, disableClearable: true, popupIcon: null, PopperComponent: StyledPopper, onInputChange: function (event, value) { return setSearchQuery(value); }, noOptionsText: _jsx(SearchNotFound, { query: searchQuery, sx: { py: 10 } }), options: allItems.sort(function (a, b) { return -b.group.localeCompare(a.group); }), groupBy: function (option) { return option.group; }, getOptionLabel: function (option) { return "".concat(option.title, " ").concat(option.path, " ").concat(option.indexKey); }, renderInput: function (params) { return (_jsx(InputBase, __assign({}, params.InputProps, { inputProps: params.inputProps, fullWidth: true, autoFocus: true, placeholder: "Search...", onKeyUp: handleKeyUp, startAdornment: _jsx(InputAdornment, __assign({ position: "start" }, { children: _jsx(Iconify, { icon: "eva:search-fill", sx: { color: 'text.disabled' } }) })), sx: { height: 1, typography: 'h6' } }))); }, renderOption: function (props, option, _a) {
                                var inputValue = _a.inputValue;
                                var title = option.title, path = option.path;
                                var partsTitle = parse(title, match(title, inputValue));
                                var partsPath = parse(path, match(path, inputValue));
                                return (_jsxs(Box, __assign({ component: "li" }, props, { onClick: function () { return handleClick(path); } }, { children: [_jsx("div", { children: partsTitle.map(function (part, index) { return (_jsx(Box, __assign({ component: "span", sx: {
                                                    typography: 'subtitle2',
                                                    textTransform: 'capitalize',
                                                    color: part.highlight ? 'primary.main' : 'text.primary',
                                                } }, { children: part.text }), index)); }) }), _jsx("div", { children: partsPath.map(function (part, index) { return (_jsx(Box, __assign({ component: "span", sx: {
                                                    typography: 'caption',
                                                    color: part.highlight ? 'primary.main' : 'text.secondary',
                                                } }, { children: part.text }), index)); }) })] })));
                            } }) }) }))] }) })));
}
export default memo(Searchbar);
function splitPath(array, key) {
    var _a;
    var stack = array.map(function (item) { return ({
        path: [item.title],
        currItem: item,
    }); });
    var _loop_1 = function () {
        var _b = stack.pop(), path = _b.path, currItem = _b.currItem;
        if (currItem.path === key) {
            return { value: path };
        }
        if ((_a = currItem.children) === null || _a === void 0 ? void 0 : _a.length) {
            stack = stack.concat(currItem.children.map(function (item) { return ({
                path: path.concat(item.title),
                currItem: item,
            }); }));
        }
    };
    while (stack.length) {
        var state_1 = _loop_1();
        if (typeof state_1 === "object")
            return state_1.value;
    }
    return null;
}
// ----------------------------------------------------------------------
function handleLoop(array, subheader) {
    return array === null || array === void 0 ? void 0 : array.map(function (list) { return (__assign(__assign({ subheader: subheader }, list), (list.children && {
        children: handleLoop(list.children, subheader),
    }))); });
}
